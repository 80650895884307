import React, { useEffect, useContext } from 'react';

import {
  Redirect,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

import {Redirect as BridgeRedirect} from '@shopify/app-bridge/actions';

import { AppBridgeContext } from '@shopify/app-bridge-react/context';

import Skeleton from './Skeleton'
import Loader from 'components/Loader';
import Articles from 'views/articles';
import Settings from 'views/settings';
import Plan from 'views/settings/plan';
import ChargeDeclined from 'views/settings/plan/ChargeDeclined';
// import NoArticles from 'views/articles/NoArticles'
            

export const AdapterLink = ({ url, external, ...rest}) => {
  return  url.substr(0,1) === '/' 
    ? <NavLink to={url} {...rest} exact activeClassName="Polaris-Navigation__Item--selected" />
    : <a href={url} target={external ? '_blank' : ''} {...rest}>{rest.children}</a>
}



export const Routes = ({ history, shop }) => {
  // const app = useContext(AppBridgeContext);
  
    // useEffect(() => {            
    //   app.subscribe(BridgeRedirect.ActionType.APP, function(redirectData) {
    //     history.push(redirectData.path)
    //   });
    // },[shop, history, app])


    return (
    <Route render={({ location }) => (
      <Switch location={location}>
        {/* <Route path="/articles/no-articles" component={NoArticles}/> */}
        <Route path="/articles/:articleId" component={Articles}/>
        <Route path="/articles/" component={Articles}/>
        <Route path="/settings/plan/charge-declined" component={ChargeDeclined}/>
        <Route path="/settings/plan" component={Plan}/>
        <Route path="/settings" component={Settings}/>
        <Redirect from="/*" to={`/articles${location.search}`}/>
        
      </Switch>
    )} />
  )
}

export const SkeletonRoutes = ({ history }) => {
  return (
      <Route render={({ location }) => (
        <Switch location={location}>
          <Route path="/dashboard" component={Skeleton.Article}/>
          <Route path="/settings" component={Loader}/>
          <Route path="/stats/*" render={() => <Loader color="white"/>}/>
          <Route path="/*" component={Skeleton.Article}/>
        </Switch>
      )} />
  )
}


