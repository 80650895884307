import '../style.scss'

import { withFirebase } from '../../providers/firebase'

import React, { useState, useEffect, useMemo }from 'react';

import { useSelector } from 'react-redux'

import { withRouter} from 'react-router-dom'

import {useLocation, useHistory, } from 'react-router-dom';


import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';

// import RoutePropagator from './RoutePropagator';

import enTranslations from '@shopify/polaris/locales/en.json';

import {
  AppProvider,
  Loading,
  Frame,
} from '@shopify/polaris';


import { Routes, SkeletonRoutes } from './Routes'
import { AdapterLink } from '../Navigation'

import InitApp from 'components/InitApp'

const Layout = (props) => { 
  const { shop, authState } = props
  const [loading, setLoading] = useState(true)
  const history = useHistory();
  const location = useLocation();
  // const history = useMemo(
  //   () => ({replace: (path) => navigate(path, {replace: true})}),
  //   [navigate],
  // );d
  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history],
  );
  const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY
  const { 
    config: { _init } = {}, 
    app: { _init:_init_app, app_installed, app_plan_active }, 
    env: { isAgent, isProd, isDev, host } 
  } = useSelector(state => state)
  const historyReplace = history.replace
  const isEmbeded = window.top !== window.self

  const config = { 
    host,
    apiKey: apiKey, 
    shopOrigin: shop, 
    forceRedirect: !isAgent && isProd,
  }
  
  useEffect(() => {    
    let currentQuery = window.location.search.replace('?','')

    const reauth = () => historyReplace(`/auth?redirect=${window.location.pathname}${currentQuery ? '&' + currentQuery : ''}`)
    if (authState === false) {
      reauth()
      
    } else if (authState && _init_app && (app_installed === false || app_installed === undefined))  { 
      // need to do this as if a user still has auth when they uninstall they won't be prompted to install. 
      // it will just try take them to the root. But AppBridge will take them into Shopify Admin where the app does not exist. 
      reauth()      
    } else if (_init_app && !app_plan_active) {      
      reauth()
    } else if (_init) {
      setLoading(false)
    }
  },[authState, setLoading, _init, _init_app, app_plan_active, app_installed, historyReplace])
        
  useEffect(() => {
    if (authState && window.location.search.indexOf('fb_token') > -1) {
      historyReplace(`${window.location.pathname}?shop=${shop}`)
    }
  },[authState, historyReplace, shop])
  
  if (authState === null) return <AppProvider>{isEmbeded ? <SkeletonRoutes/> : null}</AppProvider>
  
  return (
      <AppProvider linkComponent={AdapterLink} i18n={enTranslations}>
        <InitApp/>
        { app_installed 
          ? (
            <AppBridgeProvider 
            config={config}
            router={router}
            >
              {/* <RoutePropagator /> */}
                { isEmbeded || isDev 
                  ? loading
                    ? <SkeletonRoutes/>
                    : <Routes history={props.history} shop={shop}/>
                  : <Frame><Loading/></Frame>
                }
            </AppBridgeProvider>    
          )
          : isEmbeded 
            ? <SkeletonRoutes/>
            : <Frame><Loading/></Frame>
        }
      </AppProvider>
  )
}


export default withFirebase(withRouter(Layout))