import { withFirebase } from 'providers/firebase'

import { useEffect }from 'react';

import { useDispatch } from 'react-redux'

const InitApp = (props) => { 
  const { db, auth, shop, authState, children } = props
  const dispatch = useDispatch()
  
  useEffect(() => {    
    if (authState) {
      dispatch({ type: 'RESET_INIT' })

      // const configRef = db.doc(`shops/${shop}/settings/config`)
      //   .onSnapshot(s => { 
        db.doc(`shops/${shop}/settings/config`)
          .get()
          .then(s => {
              dispatch({
            type: 'SET_CONFIG',
            payload: s.data()
          })
        })

        db.doc(`shops/${shop}`)
        .get()
        .then(s => {                                                    
            dispatch({
              type: 'SET_APP',
              payload: s.data()
            })
          })

      const navigationRef = db.doc(`shops/${shop}/settings/navigation`)
        .onSnapshot(s => {           
        // db.doc(`shops/${shop}/settings/navigation`)
        //   .get()
        //   .then(s => {
              dispatch({
            type: 'SET_NAVIGATION',
            payload: s.data() || {}
          })
        })

      
      setTimeout(() => {
        db.doc(`shops/${shop}/shop/shop`)
          .get()
          .then(s => {                                             
            const { email, shop_owner:name } = s.data() || {}
            window.zE('webWidget', 'prefill', {
              name: {
                value: name,
              },
              email: {
                value: email,
              },
            });
          })
      }, 3000);

      const unSubscribe = () => {    
        navigationRef()   
      }

      return unSubscribe
    }
  },[db, shop, authState, dispatch, auth])


  return children || null
}

export default withFirebase(InitApp)