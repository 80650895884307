import './assets/App.scss'
// import "animate.css/source/_base.css";

import React, { useState, useEffect } from 'react';

import { Provider, useDispatch } from "react-redux";
import reduxStore from "./redux/store";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import store from 'store-js'

import Layout from './layout';
import Auth from 'views/auth';
import ErrorPage from 'views/error';
import AuthCallback from 'views/auth/callback';

import FirebaseContext, { firebase } from "./providers/firebase"

import { getShopDomain } from "./utils/auth"
import { refreshAuthPath } from './utils/links'
import { getQueryParam } from './utils/helpers'
import AdminSignin from './views/admin/signin';

import { Redirect } from '@shopify/app-bridge/actions';
import createApp from '@shopify/app-bridge';

function App(props) {
  const { location: { pathname } = {} } = props
  const [uid, setUid] = useState('')
  const [authUser, setAuthUser] = useState(null)
  const [authShop, setAuthShop] = useState(null)
  const [hasWidget, setHasWidget] = useState(true)
  const dispatch = useDispatch()
  
  const isEmbeded = window.top !== window.self
  const isDev = window.location.host === "localhost:3000"
  const shopDomain = getShopDomain()
  const host = getQueryParam('host') || btoa(`${shopDomain}/admin`)
 
  // const { shop = shopDomain } = useSelector(state => state.env)

  dispatch({ type: 'SET_SHOP', payload: shopDomain })
  dispatch({ type: 'SET_HOST', payload: host })
  dispatch({ type: 'SET_EMBEDDED', payload: isEmbeded })

useEffect(() => {
  // dispatch({ type: 'SET_SHOP', payload: shopDomain })
  
    // console.log('Setting host', host);
    // dispatch({ type: 'SET_HOST', payload: host })
    // dispatch({ type: 'SET_EMBEDDED', payload: isEmbeded })
    if (isDev) {
      dispatch({ type: 'SET_ENVIRONMENT', payload: 'development' })
    } else {
      dispatch({ type: 'SET_ENVIRONMENT', payload: 'production' })
    }
  }, [dispatch, isDev])


  useEffect(() => {
    let authWatch = firebase.auth().onAuthStateChanged((user) => {
      setAuthShop(null)
      setAuthUser(null)

 
      if (user) {
        dispatch({ type: 'SET_AGENT', payload: false })
        setUid(user.uid)
        setAuthUser(true)

        firebase.auth().currentUser.getIdTokenResult()
          .then((token) => {

            if (token.claims.shop === shopDomain) {
              setAuthShop(token.claims.shop)

            } else if (token.claims.shop && !getShopDomain()) { // do this for when shop is taken from the auth - i.e. scanner signin             

              store.set('shop', token.claims.shop)
              dispatch({ type: 'SET_SHOP', payload: token.claims.shop })
              setAuthShop(token.claims.shop)

            } else if (token.claims.is_agent
              && [process.env.REACT_APP_BASE_APP_URL, 'http://localhost:3000'].indexOf(window.location.origin) > -1
              && window.location.pathname.substr(0, 6) === '/admin') {

              store.set('is_agent', true)
              dispatch({ type: 'SET_AGENT', payload: true })
              dispatch({ type: 'SET_AGENT_ROLE', payload: token.claims.role })
              setAuthShop(shopDomain)

            } else if (!isDev) {
              firebase.auth()
                .signOut()
                .then(() => {
                  setAuthShop(false)
                  window.location.replace(`${refreshAuthPath}${window.location.search}`)
                })
            }
            return authWatch

          })
          .catch((error) => {
            console.error(error);
            setAuthShop(false)
          });
      } else {
        setAuthUser(false)
        setAuthShop(false)
      }
    })
  }, [setUid, setAuthUser, setAuthShop, shopDomain, isEmbeded, dispatch, isDev, host])


  useEffect(() => {
    if (window.zE) {
      var hideHelpPaths = ['/admin', '/auth', '/signout', '/stats', '/articles']
      var hasWidget = true

      hideHelpPaths.forEach(prefix => { if (pathname.startsWith(prefix)) hasWidget = false })

      if (pathname === '/') hasWidget = false
      setHasWidget(hasWidget)
      window.zE('webWidget', hasWidget ? 'show' : 'hide');
    }
  }, [pathname])


  return (
    <div className={hasWidget ? 'AC-App--Has-Widget' : ''}>
      <FirebaseContext.Provider value={{ shop:shopDomain, uid, authUser, authShop }}>
        <Router>
          <Switch>
            {/* Do this so that the shop is passed as a param */}
            <Route path="/admin/shops/:shop" component={Layout.Admin} />
            <Route path="/admin/signin*" exact component={AdminSignin} />
            <Route path="/admin*" component={Layout.Admin} />
            <Route path="/auth/callback" component={AuthCallback} />
            <Route path="/auth" component={Auth} />
            <Route path="/error" component={ErrorPage} />
            <Route path="/signout" component={SignOut} />
            <Route path="/" render={Layout.App} />
          </Switch>
        </Router>
      </FirebaseContext.Provider>
    </div>
  )
}

function SignOut() {
  const [hasSignout, setHasSignout] = useState(false)
  const redirect = getQueryParam('redirect')
  useEffect(() => {
    if (!hasSignout) {
      firebase.auth().signOut().then(() => {
        setHasSignout(true)
        if (redirect) window.location.replace(redirect)
      })
    }
  }, [hasSignout, redirect])
  return null
}


export default function () {
  return (
    <Provider store={reduxStore}>
      <Router>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </Provider>
  )
}
