import axios from "axios"
import { Redirect } from '@shopify/app-bridge/actions';
import createApp from '@shopify/app-bridge';




export const getShopDomain = () => {
  const queryParams = window.location.search
  const urlParams = new URLSearchParams(queryParams)
  let parentDomain = document.location.ancestorOrigins && document.location.ancestorOrigins[0] // if chrome iframe
  const shopDomain = urlParams.get('shop')
  
  
  if (typeof parentDomain !== 'undefined') {
    parentDomain = parentDomain.replace('https://', '')
  }
  
  return shopDomain || parentDomain
}


export const requestAuth = async (shopDomain, host) => {    
  const shop = shopDomain || getShopDomain()
  
  if (!shop) throw new Error("No shop defined. Please open app from within Shopify.")
  
  let baseServerUrl = process.env.REACT_APP_BASE_SERVER_URL || ''

    try {
      const { data } = await axios.post(`${baseServerUrl}/services/auth`, { shop })    
     
      if (data && data.body) {
        const redirectUrl = data.body
        
        if (window.top === window.self) {         
          window.top.location.href = redirectUrl
          return true
        } else {
          const app = createApp({
            host,
            apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
            shopOrigin: shop,
            forceRedirect: false,
          })
          
          let normalizedLink = document.createElement('a')
          normalizedLink.href = redirectUrl

          const redirect = Redirect.create(app)
          redirect.dispatch(Redirect.Action.REMOTE, normalizedLink.href)   
          return true
        }
      } else {
        throw new Error("Invalid response from authentication server")
      }
    } catch (error) {
      console.log(error);
      
      throw new Error('Error connecting to authentication server')
    }
}

export const requestTestAuth = async (shop, historyReplace) => {  
  let baseServerUrl = process.env.REACT_APP_BASE_SERVER_URL || ''
  try {    
    const { data: { fb_token } } = await axios.get(`${baseServerUrl}/services/auth-test-user?shop=${shop || ''}`)            

    historyReplace(`/auth/callback?fb_token=${fb_token}`)
  } catch (error) {
    console.warn('testUserAuth', error)
  }
      
}