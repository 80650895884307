import  './style.scss'

import { withFirebase } from 'providers/firebase'

import React, { useState, useRef, useCallback, useEffect, useLayoutEffect } from 'react';
import { Prompt } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'

// import { TitleBar } from '@shopify/app-bridge-react';
import {Redirect} from '@shopify/app-bridge/actions';

import { useHistory } from "react-router-dom";


import { 
  Page,
  Button,
  TextContainer,
  Stack,
  ButtonGroup,
  Select,
  Tooltip,
  Spinner,
  TextStyle,
 } from '@shopify/polaris';

 import {
  MobileHamburgerMajor,
  EditMinor,
  PinMinor,
} from '@shopify/polaris-icons';

import { DateTime } from 'luxon'

import useWindowSize from 'hooks/useWindowSize'

import ContentEditor from './ContentEditor'
import TitleEditor from './TitleEditor'
import Skeleton from 'layout/app/Skeleton';
import DeleteButton from 'components/DeleteButton';

import { getQueryParam } from 'utils/helpers'
import { compareHtmlAndRemoveImages, removeImagesNotInHtml } from 'utils/actions'
import { useAppBridge } from "@shopify/app-bridge-react";

function Article (props) {
  const { 
    db, 
    shop,
    article,
    articleId,
    articleRef,
    loading,
    isNew, 
    onActivateMobileNavigation,
    historyReplace,
   } = props
  
  const { 
    navigation: { 
      sections = {},
      sections_list = [], 
      navigation_items = [], 
      articles_index = {}, 
    },
  } = useSelector(s => s)

  const [ lazyArticle, setLazyArticle ] = useState(article)
  const [ edit, setEdit ] = useState(false)
  const [ uploadedImages, setUploadedImages ] = useState([])
  const [ pageTop, setPageTop ] = useState(0)
  const [ editorProcessing, setEditorProcessing ] = useState(false)

  const history = useHistory();
  const dispatch = useDispatch()
  const articleElRef = useRef(null);
  const app = useAppBridge();
  const redirect = Redirect.create(app)

  const { window: { width } } = useWindowSize();

  const section = getQueryParam('section')

  const collectionRef = `shops/${shop}/articles`

  const navigationRef = `shops/${shop}/settings/navigation`

  useEffect(() => { 
    function watchClick (e){
      
      if (edit) return 
      
      if (e.target.nodeName === 'A') {
        let el = e.target
        let href = el.href

        e.preventDefault()

        if (href.startsWith(window.location.origin)) {
          history.push(href.replace(window.location.origin, ''))
        } else {
          window.open(href)
        } 

      } else if (e.target.nodeName === 'SPAN') {
        let dataset

        if (e.target.dataset.denotationChar) {
          dataset = e.target.dataset
        } else if (e.target.parentNode.dataset.denotationChar) {
          dataset = e.target.parentNode.dataset
        } else if (e.target.parentNode.parentNode.dataset.denotationChar) {
          dataset = e.target.parentNode.parentNode.dataset
        } 

        if (!dataset) return 

        let { path, source, id } = dataset

        if (source === 'guide') {
          history.push(`${path}${id}`)
        } else if (source === 'admin') {
          redirect.dispatch(Redirect.Action.ADMIN_PATH, `${path}${id}`);
        }
      }
  }

    document.body.addEventListener("click", watchClick, false)
    
    return () => document.body.removeEventListener("click", watchClick)
   }, [history, edit, shop, redirect])

  useLayoutEffect(() => {
    let el = document.getElementById('AC_Actions_Bar')     
    if (el) setPageTop(el.getBoundingClientRect().height)
  },[edit])

  useEffect(() => {
    if (isNew) setEdit(true)
  }, [isNew]);

  const handleImageUploaded = useCallback((image) => setUploadedImages(x => [...x, image]),[])

  const toggleEdit = useCallback(() => setEdit(x => !x),[])

  const handleDelete = useCallback(() => {
    setEdit(false)
    let articlesIndex = { ...articles_index }
    delete articlesIndex[articleId]
    db.doc(articleRef).delete()
    db.doc(navigationRef).update({ articles_index: articlesIndex })
  },[db, articleRef, navigationRef, articleId, articles_index])


  const handleCancel = useCallback(() => {
    setEdit(false)
    setLazyArticle(article)
    setTimeout(() => {
      if (isNew) historyReplace('/articles')
    })
  },[article, historyReplace, isNew])


  const saveArticle = useCallback((passedArticle) => {
    const generateId = () => `${Number(new Date())}${Math.floor(Math.random() * 10)}`
    const updated = DateTime.local().toISO()
    const id = !isNew ? articleId : generateId()
    let article = passedArticle || lazyArticle
        article = { ...article, id, updated, title: article.title || '' }
    const { title, section, pinned = false } = article
    const articleIndex = { id, title, section, pinned } 

    dispatch({ type: 'SET_ARTICLE', payload: article })
    dispatch({ type: 'SET_ARTICLE_INDEX', payload: articleIndex })
    console.log(article);
    
    if (isNew) {
      db.collection(collectionRef).doc(id).set(article)
    } else {
      db.doc(articleRef).set(article)
    }

    db.doc(navigationRef).set({ articles_index: { ...articles_index, [id]: articleIndex }}, { merge: true })
    
    return id
  },[db, lazyArticle, articles_index, articleId, isNew, collectionRef, articleRef, navigationRef, dispatch])
    
  const handleChange = useCallback((val, key, save) => {  
    if (!edit && !save) return 
    let article = {...lazyArticle, [key]: val }    
    setLazyArticle(article)    
    if (save) saveArticle(article)
  }, [lazyArticle, saveArticle, edit])

  const saveAndFinishEdit = useCallback(() => {    
    setEdit(false)
    let oldHtml = article.html
    let newHtml = lazyArticle.html
    let id = saveArticle()
    compareHtmlAndRemoveImages(oldHtml, newHtml) 
    setTimeout(() => {
      if (isNew) historyReplace(`/articles/${id}`) //  inside timeout so that the edit has been set and does not fire the nav guard
    });
  },[saveArticle, historyReplace, isNew, article.html, lazyArticle.html])
  
  const handlePin = useCallback(() => {    
    handleChange(!article.pinned, 'pinned', true)
  },[article.pinned, handleChange])

  useEffect(() => {
    if ((!lazyArticle.section && sections_list.length > 0) || !sections[lazyArticle.section || '']) {
      if (sections_list[0]) handleChange(sections_list[0].id, 'section')
    }
  },[lazyArticle.section, sections_list, sections, handleChange])
  
  useEffect(() => { if (!edit) setLazyArticle(article) }, [article, edit])

  useEffect(() => { 
    if (!edit && uploadedImages.length > 0) {
      removeImagesNotInHtml(lazyArticle.html, uploadedImages)
      setUploadedImages([])
    }
  },[edit, lazyArticle.html, uploadedImages])

  useEffect(() => {
    setTimeout(() => { if (isNew && section && ! lazyArticle.section) handleChange(section, 'section') });
  }, [isNew, section, handleChange, lazyArticle.section])

  useEffect(() => {
    async function updateMentions () {
      try {
        let isDirty = false
        Object.keys(articles_index).forEach(id =>{
          let { title } = articles_index[id]
          let mentions = document.querySelectorAll(`[data-id="${id}"]`)
    
          mentions.forEach(mention => {
            if ( mention.childNodes[1] 
              && mention.childNodes[1].childNodes[1]
              && ( mention.childNodes[1].childNodes[1].data !== title || mention.dataset.value !== title)
              ) {
                isDirty = true
                mention.childNodes[1].childNodes[1].data = title
                mention.dataset.value = title
            }
          })
        })
  
        if (isDirty) handleChange(articleElRef.current.innerHTML, 'html', true)
        
      } catch (error) {
        console.error(error);
      }

    }
    if (articleElRef.current) updateMentions()
  }, [lazyArticle.html, articles_index, handleChange])
 
  if (loading) return <Skeleton.Article/>

  return ( 
    <div className={`AC-Article__Page ${edit ? ' Edit--Active' : ''}`} style={{paddingTop: edit ? pageTop : 0}}>
        { edit && 
          <SaveBar 
            navItems={navigation_items} 
            section={lazyArticle.section} 
            onChange={(v) => handleChange(v, 'section')} 
            processing={editorProcessing}
            onCancel={handleCancel}
            onSave={saveAndFinishEdit}
            onDelete={handleDelete}
            isNew={isNew}
          /> 
        }
       

        <div id="AC_Article">
          <Page>
            {/* <TitleBar title={lazyArticle.title}/> */}
            <Prompt 
              when={edit}
              message={location => `You have unsaved changes, are you sure you want to navigate away?`}
            />
              <TextContainer>
                <div className="AC-Article__Title">
                  <Stack alignment="center">
                    { width <= 770 && <Button plain icon={MobileHamburgerMajor} onClick={onActivateMobileNavigation}/> } 
                    <Stack.Item fill>
                        <TitleEditor value={lazyArticle.title} onChange={(v) => handleChange(v, 'title')} editAcive={edit}/>
                    </Stack.Item>
                    {!edit && <PinButton active={lazyArticle.pinned} onPin={handlePin}/>}
                    {!edit && <Button plain icon={EditMinor} onClick={toggleEdit}/>}
                  </Stack>
                </div>  
                <LastUpdated updated={lazyArticle.updated}/>
                { edit 
                  ? (
                    <ContentEditor 
                      value={lazyArticle.html || ''} 
                      onChange={(v) => handleChange(v, 'html')}
                      onSave={saveAndFinishEdit}
                      editActive={edit}
                      onSetLoading={setEditorProcessing}
                      onImageUploaded={handleImageUploaded}
                      imageRef={`shops/${shop}/articles/${articleId}`}
                      keepToolbarInView={pageTop}
                      />
                  )
                  : <div className="AC-Article__Page-Content">
                      <div className="ql-snow">
                        <article id="article" ref={articleElRef} className="ql-editor" dangerouslySetInnerHTML={{__html:lazyArticle.html}}></article>
                      </div>
                    </div> 
                }
              </TextContainer>
          </Page>
        </div>

      </div>   
    );
  }


  
const PinButton = ({active, onPin}) => {
  return (
    <div className={`AC-Pinned-Button ${active ? ' Active' : ''}`}>
      <Tooltip content={`${active ? 'Unpin' : 'Pin'} article to menu`}>
        <Button plain icon={PinMinor} onClick={onPin}></Button>
      </Tooltip>
    </div>
  )
}  

const LastUpdated = ({updated}) => {
  if (!updated) return null

  return (
    <div style={{margin: '1.6rem 0 1rem'}}>
      <TextStyle variation="subdued">
        Last updated: {DateTime.fromISO(updated).toLocaleString(DateTime.DATETIME_MED)}
      </TextStyle>
    </div>
  )
}

const SaveBar = ({navItems, section, onChange, processing, onCancel, onSave, onDelete, isNew}) => {
  const [ lazySection, setLazySection ] = useState(section)

  const handleChange = useCallback((val) => {
    setLazySection(val)
    setTimeout(() => onChange(val))
  },[onChange])

  const editorButtons = (
    <ButtonGroup>
      { !isNew && <DeleteButton disabled={processing} plain destructive onDelete={onDelete}>Delete article</DeleteButton> }
      <Button disabled={processing} onClick={onCancel} >Discard changes</Button>
      <Button disabled={processing} primary onClick={onSave} >Save</Button>
    </ButtonGroup>
  )

  return (
    <div id="AC_Actions_Bar" className="AC-Actions-Bar">
      <Stack alignment="center">
        <label className="Polaris-Label__Text">Section</label>
        <Stack.Item>
          <div style={{width: '300px'}}>
          <Select 
            value={lazySection} 
            options={navItems.map(({label, id}) => ({ label, value: id }))}
            onChange={handleChange}
          />
          </div>
        </Stack.Item>
        <Stack.Item fill>
          { processing && 
            <div style={{justifyContent: 'flex-end', display: 'flex', alignItems: 'center'}}>
              <Spinner size="small"/> <span style={{marginLeft: '0.8rem'}}></span>
            </div>
          }
        </Stack.Item>
        <Stack.Item >{editorButtons}</Stack.Item>
      </Stack>
    </div>
  )
}



export default withFirebase(Article)
